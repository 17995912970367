import { useEffect } from "react";
import { ShareIcon } from '../icons/common/share';
import { FacebookIcon } from '../icons/common/facebook';
import { TwitterIcon } from '../icons/common/twitter';
import { WhatsAppIcon } from '../icons/common/whatsapp';
import './style.css';

export function NewsShare() {

	useEffect(() => {
		(async () => {
			try {
				//console.log('NewsShare');
			} catch (error) {
				console.log('src/components/NewsShare Erro: ', error);
			}
		})();
	}, []);

	const currentUrl = window.location.href;

	return (
		<div className="NewsShare">
			<div className="NewsShare-container">
				<div className="NewsShare-titulo">Compartilhar</div>
				<div className="NewsShare-btn"><ShareIcon /></div>
				<div className="NewsShare-btn facebook" title="Facebook">
					<a
						href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FacebookIcon />
					</a>
				</div>
				<div className="NewsShare-btn twitter" title="Twitter - X">
					<a
						href={`https://x.com/intent/post?url=${encodeURIComponent(currentUrl)}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<TwitterIcon />
					</a>
				</div>
				<div className="NewsShare-btn whatsapp" title="Whatsapp">
					<a
						href={`https://wa.me?text=${encodeURIComponent(currentUrl)}`}
						target="_blank"
						rel="noopener noreferrer"
						data-action="share/whatsapp/share"
					>
						<WhatsAppIcon />
					</a>
				</div>
			</div>
		</div>
	);
}