import { useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { TitlePage } from "../../components/TitlePage";
import { useEffect, useState } from "react";
import { api, urls } from "../../services/api";
import { INewsComplet } from "../../interfaces/news";
import { SubTitlePage } from "../../components/SubTitlePage";
import { ListImages } from "../../components/ListImages";
import { MapSite } from "../../components/MapSite";
import { OtherNews } from "../../components/OtherNews";
import { ItemCategorie } from "./components/itemCategorie";
import FlatList from "flatlist-react/lib";
import { IArticle } from "../../interfaces/article";
import { IMenuItem, IMenu } from "../../interfaces/menu";
import { Footer } from "../../components/Footer";
import { useApp } from "../../context/app";
import { HeaderMobile } from "../../components/HeaderMobile";
import { SearchMobile } from "../../components/SearchMobile";
import { ServicesAccordion } from "../../components/ServicesAccordion";
import { Banner } from "../../components/Banner";
import VLibras from '@djpfs/react-vlibras';
import { NewsShare } from "../../components/NewsShare";
import '../../assets/css/root.css';
import '../../assets/css/global.css';
import '../../assets/css/tema_escuro.css';
import './style.css';

export function ArticleScreen() {

	let { id } = useParams();

	const { mobile, visible, configApiURL } = useApp()

	const [article, setArticle] = useState<IArticle>(
		{
			content: '',
			published: '',
			title: 'Carregando...'
		}
	)

	const [listMenu, setMenuList] = useState<IMenu[]>([])

	const getArticleById = async (id: number) => {
		try {
			const response = await api.get(urls.Article + id)
			setArticle(response.data)
		} catch (error) {
			console.log('Error get Article')
			console.log(error)
		}
	}

	useEffect(() => {

		id && getArticleById(parseInt(id))
		
	}, [])

	useEffect(() => {
		(async () => {
			
			const response = await api.get(urls.menus)
			const menu: IMenuItem[] = response.data
			var menuFiltered: IMenu[] = []
			var idSection = 0
			
			menu[0].secoes.map(itemSecoes => {
				itemSecoes.menus?.map(itemMenu => {
					if (parseInt(id || '0') == itemMenu.ref_cod_artigo) {
						idSection = itemMenu.ref_cod_menu_secao || 0
					}
				})
				if (itemSecoes.cod_menu_secao == idSection) {
					menuFiltered = itemSecoes.menus as IMenu[]
				}
			})
			
			setMenuList([...menuFiltered])
			
		})()
	}, [article])


	const handleGetArticle = async (id: number) => {
		//console.log('pages/Article ln 78 - id:', id)
		getArticleById(id)
	}

	const url = window.location.href
	const url_split = url.split("/")
	const artigo_id_url = Number(url_split[url_split.length - 1])
	//console.log('artigo_id_url:', artigo_id_url)

	//console.log( 'article', article );
	
	const apiUrl = configApiURL.replace("portaladm-pmitajai/api/", "public/")
	const content = article?.content.replaceAll('src="/', `src="${apiUrl}`)

	if (mobile) {

		return (
			<div className="pages-mobile-Article">
				
				<HeaderMobile />

				<SearchMobile />

				<TitlePage title={article?.title} />

				<div className="pages-mobile-Article-conteudo">

					<div 
						className="pages-mobile-Article-conteudo-box"
						dangerouslySetInnerHTML={{ __html: content }}
					/>
					
				</div>
				
				<div className="pages-mobile-Article-conteudo">
					
					<NewsShare />
					
				</div>

				<ServicesAccordion />

				<Banner mobile={mobile} />

				<div className="pages-mobile-Article-separador" />

				<MapSite />

				<Footer />
				
				{
				
					visible && 
					
					(
					
						<VLibras forceOnload />
						
					)
					
				}
				
			</div>
			
		)
		
	}

	return (
		<div className="pages-Article">
			
			<Header 
				search 
				route={` ${article.title ? article.title : 'Não encontrado'}`} 
			/>

			<TitlePage
				title={article?.title ? article?.title : 'Sem Nome'}
			/>

			<div className="pages-Article-container">

				{
					
					listMenu.length > 0 && (

						<div className="pages-Article-col-esq">

							<div className="pages-Article-col-esq-topo"></div>

							<FlatList
								list={listMenu}
								renderItem={(item) => (
								
									<ItemCategorie 
										idActual={artigo_id_url} 
										newsCategorie={item} 
									/>
									
								)}
							/>
							
						</div>
						
					)
					
				}

				{
					
					article.content ? (

						<div
							className={listMenu.length > 0 ? "pages-Article-conteudo" : "pages-Article-conteudo full"}
							dangerouslySetInnerHTML={{ __html: content }}
						/>
						
					) 
					
					: 
					
					(

						<div 
							className={listMenu.length > 0 ? "pages-Article-vazio" : "pages-Article-vazio full"}
						>Sem texto</div>
						
					)
					
				}
				
			</div>
			
			<div className="pages-Article-container">
			
				<NewsShare />
				
			</div>

			<MapSite />

			<Footer />
			
			{
				
				visible && 
				
				(
				
					<VLibras forceOnload />
					
				)
				
			}
			
		</div>
		
	)
	
}